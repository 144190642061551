import { faDownload, faPortrait, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CustomInput,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { formatBearerToken } from '../../../../../Common.functions';
import { deleteData, fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import { clone } from '../../../../../Services/schedule';
import UserPhoto from '../../../../DragAndDropFiles/UserPhoto/UserPhoto';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import UserPhotoTypes from '../../../../DragAndDropFiles/UserPhoto/UserPhotoTypes';
import FocusPalette from '../../../Coordinator/Schedule/FocusPalette';
import style from './UserDetails.style';
import moment from 'moment';
import { useAlert } from 'react-alert';
import { CSVLink } from 'react-csv';
import { RouterPrompt } from 'Body/RezRATE/Common/RouterPrompt/RouterPrompt';

import { processExportCandidates, processExportPDF } from 'Services/CSVExports';
import { createPDFForCandidateEvaluationsWithAnswers } from 'Services/PDFMakeTemplates/CandidateEvaluations/CandidateEvaluationsAnswered';
import Loading from 'Body/Statuses/Loading';

let typeTimeout = null;
const UserDetails = ({
  selectedUser,
  dContext,
  token,
  onUpdate,
  isUpdatingUser,
  selfUserDetails,
  updateUserPhotoInUserArray,
  hasUserDetailChanges,
  setHasUserDetailChanges,
  scaleData,
}) => {
  const [user, setUser] = useState({});
  const [loggedInUser, setLoggedInUser] = useState({});
  const [CSVData, setCSVData] = useState([]);
  const [detailedUser, setDetailedUser] = useState(); // Used as rollback for fail update
  const [newEmailRequest, setNewEmailRequest] = useState();

  const [selectedFocuses, setSelectedFocuses] = useState([]);
  const [updatedSelectedFocuses, setUpdatedSelectedFocuses] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSendingEmailRequest, setIsSendingEmailRequest] = useState(false);

  const [AllowPrescreen, setAllowPrescreen] = useState(false);

  const [showEmailRequestModal, setShowEmailRequestModal] = useState(false);

  const [isGettingExportData, setIsGettingExportData] = useState(false);

  const [downloadType, setDownloadType] = useState('prescreen');

  const { getTokenSilently } = useAuth0();
  const alert = useAlert();

  const {
    UserPhotoUrl,
    UserFirst,
    UserMiddle,
    UserLast,
    UserSuffix,
    DefaultMeetingRoomLabel,
    UserCellPhoneNumber,
    UserEmail,
    HideCandidateScores,
    HideCandidateDocuments,
    DisableCandidateEvaluations,
    SignedAgreementDate,
    LastLoginDate,
    isEvaluator = true,
    pk_User,
  } = user;

  const updateUserPhoto = (id, photoUri) => {
    // this will update the user photo in the array of users
    updateUserPhotoInUserArray(id, photoUri);

    // also need to update the local user object
    setUser((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);
      clone.UserPhotoUrl = photoUri;
      return clone;
    });
  };

  useEffect(() => {
    getSeasonSettings();
  }, []);

  useEffect(() => {
    if (user && detailedUser) {
      const keysToCheck = ['UserFirst', 'UserMiddle', 'UserLast', 'UserSuffix', 'UserCellPhoneNumber'];

      let hasChange = false;

      keysToCheck.forEach((key) => {
        if (user[key] != detailedUser[key]) {
          hasChange = true;
        }
      });

      setHasUserDetailChanges(hasChange);
    }
  }, [user, detailedUser]);

  useEffect(() => {
    if (selectedUser) {
      getUserInfo(selectedUser);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (CSVData && CSVData.length > 0) {
      // CSVData.splice(0, 1);
      document.getElementById('download_actual_evaluation').click();
    }
  }, [CSVData]);

  useEffect(() => {
    if (dContext && dContext.season) {
      setAllowPrescreen(dContext.season.AllowPrescreen);
    }
  }, [dContext]);

  const getSeasonSettings = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            dContext.setSeason({ ...dContext.season, ...res.data });
            setAllowPrescreen(res.season.AllowPrescreen);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  // If newUser is {}, it gets theh logged in user's details.
  const getUserInfo = (newUser) => {
    if (newUser == null) {
      return;
    }

    getTokenSilently().then((token) => {
      setIsFetching(true);
      fetchDataAgnostic(
        'department/user',
        {
          pk_Department: dContext.department.pk_Department,
          pk_User: newUser.pk_User,
        },
        formatBearerToken(token),
      )
        .then((res) => {
          if (Object.keys(newUser).length === 0 && newUser.constructor === Object) {
            setLoggedInUser(res.data);
          }
          const newUserActual = clone({ ...newUser, ...res.data });
          // newUserActual.pk_User = newUser.pk_User;
          newUserActual.EnableAdmin = newUser.EnableAdmin;
          newUserActual.EnableEvaluator = newUser.EnableEvaluator;
          newUserActual.EnablePointOfContact = newUser.EnablePointOfContact;

          const focusIds = [];
          if (newUser.Focuses) {
            newUser.Focuses.forEach((focus) => {
              focusIds.push(focus.pk_Focus);
            });
          }
          setNewEmailRequest(newUserActual.Auth0Email || '');
          setUser(newUserActual);
          setDetailedUser(newUserActual);
          setSelectedFocuses(focusIds);
          setUpdatedSelectedFocuses(focusIds);
          setIsFetching(false);
        })
        .catch((err) => {
          setIsFetching(false);
        });
    });
  };

  const deactivateUser = () => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/user',
          {
            pk_Department: dContext.department.pk_Department,
            pk_User: selectedUser.pk_User,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            if (onUpdate) {
              onUpdate();
            }
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {});
  };

  const reactivateUser = () => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/user/reactivate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_User: selectedUser.pk_User,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            if (onUpdate) {
              onUpdate();
            }
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        setIsUpdating(false);
      });
  };

  const handleChange = (key, value, isAutoSave) => {
    const newUser = clone(user);
    newUser[key] = value;
    setUser(newUser);

    if (isAutoSave) {
      autoSave(key, value);
    }
  };

  const autoSave = (key, value) => {
    const userObject = {};
    userObject[key] = value;
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/user',
          { pk_Department: dContext.department.pk_Department, pk_User: user.pk_User },
          {
            userObject,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Edits saved!');
            setIsUpdating(false);
            if (onUpdate) {
              onUpdate();
            }
          })
          .catch((err) => {
            Swal.fire('Oops,', 'Something went wrong, please try again later or contact support', 'error');
            setIsUpdating(false);
            if (onUpdate) {
              onUpdate();
            }
          });
      })
      .catch((err) => {
        setIsUpdating(false);
        if (onUpdate) {
          onUpdate();
        }
      });
  };

  const updateUser = (userToUpate) => {
    const {
      EnableAdmin,
      EnableAssistant,
      EnableEvaluator,
      EnablePastInterviewDates,
      EnablePointOfContact,
      HideCandidateScores,
      HideCandidateDocuments,
      DisableCandidateEvaluations,
      UserAltEmail,
      UserCellPhoneNumber,
      UserDisplayName,
      UserEmail,
      UserFirst,
      UserLast,
      UserMiddle,
      UserOfficePhoneNumber,
      UserOtherPhoneNumber,
      UserSuffix,
      pk_User,
      focusesToDelete = [],
      focusesToAdd = [],
    } = userToUpate;
    const pk_User_Actual = userToUpate.pk_User;
    const userObject = {
      UserFirst,
      UserMiddle,
      UserLast,
      UserSuffix,
      UserDisplayName,
      UserCellPhoneNumber,
      UserOfficePhoneNumber,
      UserOtherPhoneNumber,
      UserEmail,
      UserAltEmail,
      EnableAdmin,
      EnableEvaluator,
      EnableAssistant,
      EnablePointOfContact,
      EnablePastInterviewDates,
      HideCandidateScores,
      HideCandidateDocuments,
      DisableCandidateEvaluations,
    };

    const FocusesToAdd = focusesToAdd.map((f) => {
      return { pk_Focus: f };
    });
    const FocusesToDelete = focusesToDelete.map((f) => {
      return { pk_Focus: f };
    });

    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/user',
          { pk_Department: dContext.department.pk_Department, pk_User: pk_User_Actual },
          {
            userObject,
            FocusesToAdd,
            FocusesToDelete,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            alert.success('Edits saved!');
            if (onUpdate) {
              onUpdate();
            }
          })
          .catch((err) => {
            setIsUpdating(false);
            setUser(detailedUser);
            // setUserReference(detailedUser);
            Swal.fire('Oops,', 'Something went wrong, please try again later or contact support', 'error');
            if (onUpdate) {
              onUpdate();
            }
          });
      })
      .catch((err) => {});
  };

  const processExportPDF = (options) => {
    const { items, type } = options;

    if (items.length <= 0) {
      setIsGettingExportData(false);
      Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
      return;
    }
    // createPDFForCandidateEvaluationsWithAnswers({
    //   department,
    //   candidates: items,
    //   type,
    //   scaleData,
    //   callback: () => {
    //     setIsGettingExportData(false);
    //   },
    // });

    createPDFForCandidateEvaluationsWithAnswers({
      department,
      evaluators: items,
      type,
      scaleData,
      ProgramType: dContext.department.ProgramType,
      callback: () => {
        console.log('processExportPDF: ');
        setIsGettingExportData(false);
      },
    });
  };

  const getUserEvaluations = (type, callback) => {
    setIsGettingExportData(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/evaluation/responses/all',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          {
            type,
            groupBy: 'user',
            pk_Users: [selectedUser.pk_User],
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (callback) {
              callback({ items: res.data || [], type });
              return;
            }
            setIsGettingExportData(false);
            const newData = processExportCandidates({ items: res.data, type });

            if (newData) {
              setCSVData(newData);
            }

            // processExportCandidates(res.data, type);
            // setCSVData(processExportCandidates({ items: res.data, type }));
          })
          .catch((err) => {
            console.log('getUserEvaluations err: ', err);
            setIsGettingExportData(false);
            if (callback) {
              return;
              callback({ items: [], type });
            }
          });
      })
      .catch((err) => {
        setIsGettingExportData(false);
      });
  };

  const sendEmailRequest = () => {
    const { department = {} } = dContext || {};
    const { DepartmentName, OrganizationName, pk_Department } = department;
    const { UserFirst = '', UserLast = '', Auth0Email = '' } = user || {};

    const emailData = [
      {
        Email: `support@rezrate.com`,
      },
    ];

    const subject = `Email Change Request for ${DepartmentName} at ${OrganizationName}`;
    const fullEmail = `Please change the email address for ${UserFirst} ${UserLast} from ${Auth0Email} to ${newEmailRequest} in ${DepartmentName} at ${OrganizationName}. \nRequested by: ${
      loggedInUser ? loggedInUser.UserFirst : ''
    } ${loggedInUser ? loggedInUser.UserLast : ''} ${loggedInUser ? `(${loggedInUser.Auth0Email})` : ''}`;
    setIsSendingEmailRequest(true);

    getTokenSilently().then((token) => {
      postDataAgnostic(
        'department/admin/sendCandidateEmails',
        {
          pk_Department: pk_Department,
        },
        { candidateEmails: emailData, emailBody: fullEmail, from: loggedInUser.Auth0Email, subject },
        formatBearerToken(token),
      )
        .then((result) => {
          Swal.fire('Success!', 'Your request has been sent.', 'success');
          setShowEmailRequestModal(false);
        })
        .catch((err) => {
          console.log('err: ', err);
          Swal.fire(
            'Error',
            'Something went wrong sending the email request, please contact support or try again later.',
            'error',
          );
        })
        .finally(() => {
          setIsSendingEmailRequest(false);
        });
    });
  };

  const deleteUserPhoto = () => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'files/admin/user/photo',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_User: selectedUser.pk_User,
          },
          {},
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Successfully deleted photo');
          })
          .catch((err) => {
            alert.error('Failed to delete photo.');
          });
      })
      .catch((err) => {
        alert.error('Failed to delete photo');
      });
  };

  const renderEmailRequestModal = () => {
    const { department = {} } = dContext || {};
    const { UserFirst = '', Auth0Email = '' } = user || {};

    return (
      <Modal isOpen={showEmailRequestModal} toggle={() => setShowEmailRequestModal(false)} centered>
        <ModalHeader toggle={() => setShowEmailRequestModal(false)}>Request Email Change for {UserFirst}</ModalHeader>
        <ModalBody>
          <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
            <div style={{ ...style.simpleColumn, width: '100%', paddingRight: 30 }}>
              <div style={style.inputField}>
                <Label for="email">New Email</Label>
                <Input
                  id="email"
                  type="text"
                  key={`email_${pk_User}`}
                  value={newEmailRequest || ''}
                  placeholder="No UserEmail"
                  onChange={(e) => {
                    setNewEmailRequest(e.target.value);
                  }}
                  style={style.inputActual}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setShowEmailRequestModal(false);
            }}
          >
            Cancel
          </Button>{' '}
          <Button
            disabled={newEmailRequest === Auth0Email || isSendingEmailRequest}
            color="primary"
            onClick={() => {
              sendEmailRequest();
            }}
          >
            Request Change
          </Button>
          {/* <a
            style={{ display: 'none' }}
            id="sendEmailRequestProper"
            href={`mailto:support@rezrate.com?subject=Email Change Request for ${DepartmentName} at ${OrganizationName}&body=Please change the email address for ${UserFirst} ${UserLast} from ${UserEmail} to ${newEmailRequest} in ${DepartmentName} at ${OrganizationName}.`}
          ></a> */}
        </ModalFooter>
      </Modal>
    );
  };

  const { EnableAdmin, EnableEvaluator, EnablePointOfContact, Deleted } = user;
  const { department, season = {} } = dContext;

  const myPk_User = department ? department.fk_User : null;

  if (!selectedUser || selectedUser.pk_User == null) {
    return (
      <div
        style={{
          width: '100%',
          height: '40vh',
          fontWeight: 'bold',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {' '}
        Please add or select a user
      </div>
    );
  }

  return (
    <Card>
      <CardHeader>
        <div style={style.spacedBetweenRow}>
          <div style={style.titleLabel}> {`${UserFirst && UserLast ? `${UserFirst} ${UserLast}` : 'New User'} `}</div>
          <Button
            size="sm"
            style={style.filterChoiceButton}
            color={Deleted ? 'success' : 'danger'}
            onClick={() => {
              if (Deleted) {
                Swal.fire({
                  title: 'Warning',
                  text: `Are you sure you want to re-activate ${UserFirst || ''} ${UserLast || ''}?`,
                  showCancelButton: true,
                  confirmButtonText: 'Re-activate',
                  confirmButtonColor: 'green',
                }).then((result) => {
                  if (result.value) {
                    reactivateUser();
                  }
                });
              } else {
                Swal.fire({
                  title: 'Warning',
                  text: `Are you sure you want to deactivate ${UserFirst || ''} ${UserLast || ''}?`,
                  showCancelButton: true,
                  confirmButtonText: 'Deactivate',
                  confirmButtonColor: 'red',
                }).then((result) => {
                  if (result.value) {
                    deactivateUser();
                  }
                });
              }
            }}
          >
            <div style={style.spacedBetweenRow}>
              <FontAwesomeIcon icon={Deleted ? faRedo : faTrash} style={{ marginRight: 10 }} />
              <div style={style.centeredText}> {Deleted ? 'Activate User' : 'Deactivate User'}</div>
            </div>
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <div style={style.simpleRow}>
          <Modal isOpen={isGettingExportData} centered style={{ maxWidth: '60%' }}>
            <ModalBody>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Loading
                  options={{
                    labelText: 'Processing export data. . .',
                    labelStyle: { fontSize: 15, textAlign: 'center', fontWeight: 'bold' },
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <div style={{ ...style.simpleColumn, width: '75%', paddingRight: 30 }}>
            <div style={style.simpleRow}>
              <div style={{ ...style.simpleColumn, width: '40%', justifyContent: 'center', alignItems: 'center' }}>
                {/* {UserPhotoUrl || (selectedUser && selectedUser.UserPhotoUrl) ? ( */}
                <UserPhoto
                  externalAPI={true}
                  imgStyle={{ ...style.imgPlaceHolder, height: 300, width: '100%' }}
                  id={selectedUser ? selectedUser.pk_User : 0}
                  photoUrl={UserPhotoUrl}
                  callback={updateUserPhoto}
                  // handleDelete={() => {
                  //   deleteUserPhoto();
                  // }}
                  type={UserPhotoTypes.AdminEvaluator}
                />
                {/* ) : (
                  <FontAwesomeIcon style={{ fontSize: 300 }} icon={faPortrait} />
                )} */}

                {/* { <div style={style.signedAgreementText}>
                  <b>Signed Agreement Date:</b> {SignedAgreementDate || 'No Signed Agreement Date'}
                </div>} */}
                <div style={style.signedAgreementText}>
                  <b>Last Login:</b>{' '}
                  {user.LastLoggedIn ? moment(user.LastLoggedIn).format('MMM DD, YYYY hh:mm A') : 'Never'}
                </div>
              </div>
              <div style={{ ...style.simpleColumn, width: '60%', paddingLeft: 20 }}>
                <div style={style.simpleRow}>
                  <div style={{ ...style.simpleColumn, width: '50%', paddingRight: 4 }}>
                    <div style={style.inputField}>
                      <Label for="firstName">First Name</Label>
                      <Input
                        key={`firstName_${pk_User}`}
                        id={`firstName_${pk_User}`}
                        type="text"
                        disabled={isFetching}
                        defaultValue={user.UserFirst || ''}
                        placeholder={`${user.UserFirst || 'First Name'}`}
                        style={style.inputActual}
                        onChange={(e) => {
                          handleChange('UserFirst', e.target.value);
                        }}
                      />
                    </div>

                    <div style={style.inputField}>
                      <Label for="middleName">Middle Name</Label>
                      <Input
                        key={`middleName_${pk_User}`}
                        id="middleName"
                        type="text"
                        disabled={isFetching}
                        value={user.UserMiddle}
                        placeholder="Middle Name"
                        style={style.inputActual}
                        onChange={(e) => {
                          handleChange('UserMiddle', e.target.value);
                        }}
                      />
                    </div>
                    <div style={style.inputField}>
                      <Label for="lastName">Last Name</Label>
                      <Input
                        id="lastName"
                        type="text"
                        key={`lastName_${pk_User}`}
                        disabled={isFetching}
                        value={user.UserLast || ''}
                        placeholder="Last Name"
                        style={style.inputActual}
                        onChange={(e) => {
                          handleChange('UserLast', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ ...style.simpleColumn, width: '50%', paddingLeft: 4 }}>
                    {/* <div style={style.inputField}>
                      <Label for="interviewRoom">Interview Room</Label>
                      <Input
                        id="interviewRoom"
                         disabled={isFetching}
                        key={`interviewRoom_${pk_User}`}
                        type="text"
                        value={user.DefaultMeetingRoomLabel || ''}
                        placeholder="Interview Room"
                        style={style.inputActual}
                        onChange={(e) => {
                          handleChange('DefaultMeetingRoomLabel', e.target.value);
                        }}
                      />
                    </div> */}
                    <div style={style.inputField}>
                      <Label for="cellular">Cell Phone</Label>
                      <Input
                        id="cellular"
                        disabled={isFetching}
                        type="text"
                        key={`cellular_${pk_User}`}
                        value={user.UserCellPhoneNumber || ''}
                        placeholder="Cellular"
                        style={style.inputActual}
                        onChange={(e) => {
                          handleChange('UserCellPhoneNumber', e.target.value);
                        }}
                      />
                    </div>
                    <div style={style.inputField}>
                      <Label for="suffix">Suffix</Label>
                      <Input
                        id="suffix"
                        disabled={isFetching}
                        type="text"
                        key={`suffix_${pk_User}`}
                        value={user.UserSuffix || ''}
                        placeholder="Suffix"
                        style={style.inputActual}
                        onChange={(e) => {
                          handleChange('UserSuffix', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    disabled={!hasUserDetailChanges}
                    color="success"
                    size="sm"
                    style={{ width: 120 }}
                    onClick={() => {
                      updateUser(user);
                    }}
                  >
                    Save
                  </Button>
                </div>
                <div style={style.simpleRow}>
                  <div
                    style={{ ...style.inputField, width: '100%' }}
                    onClick={() => {
                      setShowEmailRequestModal(true);
                    }}
                  >
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      type="text"
                      key={`email_${pk_User}`}
                      value={user.Auth0Email || ''}
                      placeholder="No UserEmail"
                      style={{ ...style.inputActual, backgroundColor: '#e9ecef', cursor: 'pointer' }}
                      onFocus={(e) => {
                        e.target.blur();
                        setShowEmailRequestModal(true);
                      }}
                    />
                  </div>
                  {renderEmailRequestModal()}
                </div>
              </div>
            </div>
          </div>
          <div style={{ ...style.simpleColumn, width: '25%' }}>
            <Card>
              <CardHeader>
                <div style={style.centeredText}>
                  <b>Set Evaluator Focus</b>
                </div>
              </CardHeader>
              <CardBody>
                <FocusPalette
                  forcedDisable={!user.EnableEvaluator}
                  // isUpdating={isUpdating}
                  showIgnoreSwitch={false}
                  reference={user.Focuses}
                  updateSelectedFocuses={(newUpdatedSelectedFocuses) => {
                    setUpdatedSelectedFocuses(newUpdatedSelectedFocuses);

                    const focusesToAdd = [];
                    const focusesToDelete = [];

                    newUpdatedSelectedFocuses.forEach((new_pk_focus) => {
                      const oldFocus = user.Focuses.find((oldFocus) => {
                        return oldFocus.pk_Focus == new_pk_focus;
                      });

                      if (
                        !oldFocus
                        // &&
                        // user.Focuses.findIndex((focus) => {
                        //   return focus.pk_Focus == new_pk_focus;
                        // }) <= 0
                      ) {
                        focusesToAdd.push(new_pk_focus);
                      }
                    });
                    if (user.Focuses && selectedUser) {
                      user.Focuses.forEach((oldFocus) => {
                        const newFocus = newUpdatedSelectedFocuses.find((new_pk_focus) => {
                          return new_pk_focus == oldFocus.pk_Focus;
                        });

                        if (!newFocus) {
                          focusesToDelete.push(oldFocus.pk_Focus);
                        }
                      });
                      updateUser({ focusesToDelete, focusesToAdd, pk_User: selectedUser.pk_User, ...selectedUser });
                    }
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </div>
        <div style={{ ...style.simpleRow, justifyContent: 'center', marginTop: 30 }}>
          <div style={{ ...style.simpleColumn, width: '37%', paddingRight: 30, height: '50%' }}>
            <Card>
              <CardHeader>
                <div style={style.centeredText}>
                  <b>User Roles</b>
                </div>
              </CardHeader>
              <CardBody>
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  <div style={{ ...style.simpleColumn, width: '10%', minWidth: 40 }}>
                    <CustomInput
                      disabled={isUpdating || myPk_User == user.pk_User}
                      type="switch"
                      id={`asAdmin_${pk_User}`}
                      name="asAdmin"
                      checked={EnableAdmin}
                      onChange={(e) => {
                        handleChange('EnableAdmin', !EnableAdmin, true);
                      }}
                    />
                  </div>
                  <div style={{ ...style.simpleColumn, width: '90%' }}>
                    <b>Administrator</b>
                    <p>User can manage all aspects of the recruitment process.</p>
                  </div>
                </div>
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  <div style={{ ...style.simpleColumn, width: '10%', minWidth: 40 }}>
                    <CustomInput
                      disabled={isFetching}
                      type="switch"
                      id={`asEvaluator_${pk_User}`}
                      name="asEvaluator"
                      checked={EnableEvaluator}
                      onChange={(e) => {
                        handleChange('EnableEvaluator', !EnableEvaluator, true);
                      }}
                    />
                  </div>
                  <div style={{ ...style.simpleColumn, width: '90%' }}>
                    <b>Evaluator</b>
                    <p>User can be scheduled to interview and evaluate candidates.</p>
                  </div>
                </div>
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  <div style={{ ...style.simpleColumn, width: '10%', minWidth: 40 }}>
                    <CustomInput
                      disabled={isFetching}
                      type="switch"
                      //   label="Limit by Tags"
                      id={`asPointContact_${pk_User}`}
                      name="asPointContact_"
                      checked={EnablePointOfContact}
                      onChange={(e) => {
                        handleChange('EnablePointOfContact', !EnablePointOfContact, true);
                      }}
                    />
                  </div>
                  <div style={{ ...style.simpleColumn, width: '90%' }}>
                    <b>Point of Contact</b>
                    <p>Point of Contact.</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div style={{ ...style.simpleColumn, width: '37%', paddingRight: 30 }}>
            <Card>
              <CardHeader>
                <div style={style.centeredText}>
                  <b>Evaluator Settings</b>
                </div>
              </CardHeader>
              <CardBody>
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  <div style={{ ...style.simpleColumn, width: '10%', minWidth: 40 }}>
                    <CustomInput
                      disabled={isUpdating || (selectedUser && !selectedUser.EnableEvaluator)}
                      type="switch"
                      id={`hide_cScores${pk_User}`}
                      name="hide_cScores"
                      checked={HideCandidateScores}
                      onChange={(e) => {
                        handleChange('HideCandidateScores', !HideCandidateScores, true);
                      }}
                    />
                  </div>
                  <div style={{ ...style.simpleColumn, width: '90%' }}>
                    <b>Hide Candidate Scores</b>
                  </div>
                </div>
                <br />
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  <div style={{ ...style.simpleColumn, width: '10%', minWidth: 40 }}>
                    <CustomInput
                      disabled={isUpdating || (selectedUser && !selectedUser.EnableEvaluator)}
                      type="switch"
                      id={`hide_cDocuments${pk_User}`}
                      name="hide_cDocuments"
                      checked={HideCandidateDocuments}
                      onChange={(e) => {
                        handleChange('HideCandidateDocuments', !HideCandidateDocuments, true);
                      }}
                    />
                  </div>
                  <div style={{ ...style.simpleColumn, width: '90%' }}>
                    <b>Hide Candidate Documents</b>
                  </div>
                </div>
                <br />
                <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
                  <div style={{ ...style.simpleColumn, width: '10%', minWidth: 40 }}>
                    <CustomInput
                      disabled={isUpdating || (selectedUser && !selectedUser.EnableEvaluator)}
                      type="switch"
                      id={`disable_cEvaluations${pk_User}`}
                      name="disable_cEvaluations"
                      checked={DisableCandidateEvaluations}
                      onChange={(e) => {
                        handleChange('DisableCandidateEvaluations', !DisableCandidateEvaluations, true);
                      }}
                    />
                  </div>
                  <div style={{ ...style.simpleColumn, width: '90%' }}>
                    <b>Hide Evaluation Forms</b>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div style={{ ...style.simpleColumn, width: '25%', paddingTop: 0 }}>
            <Card>
              <CardHeader>
                <div style={style.centeredText}>
                  <b>Export Evaluations</b>
                </div>
              </CardHeader>
              <CardBody>
                <div style={{ ...style.simpleColumn, width: '100%' }}>
                  {AllowPrescreen ? (
                    <>
                      <Button
                        color="primary"
                        disabled={!selectedUser || (selectedUser && !selectedUser.EnableEvaluator) || !EnableEvaluator}
                        style={{ width: '100%', marginRight: 10 }}
                        onClick={() => {
                          setDownloadType('Prescreen');
                          getUserEvaluations('prescreen');
                        }}
                      >
                        <div style={style.spacedBetweenRow} id="exportCompleted">
                          <div>
                            <FontAwesomeIcon icon={faDownload} />
                          </div>
                          <div style={{ ...style.centeredText, fontSize: 15 }}>Prescreen Data (CSV)</div>
                        </div>
                      </Button>
                      <Button
                        color="primary"
                        disabled={!selectedUser || (selectedUser && !selectedUser.EnableEvaluator) || !EnableEvaluator}
                        style={{ width: '100%', marginRight: 10, marginTop: 10 }}
                        onClick={() => {
                          setDownloadType('Prescreen');
                          getUserEvaluations('prescreen', processExportPDF);
                        }}
                      >
                        <div style={style.spacedBetweenRow} id="exportCompleted">
                          <div>
                            <FontAwesomeIcon icon={faDownload} />
                          </div>
                          <div style={{ ...style.centeredText, fontSize: 15 }}>Prescreen PDF</div>
                        </div>
                      </Button>
                    </>
                  ) : null}

                  <Button
                    color="primary"
                    disabled={!selectedUser || (selectedUser && !selectedUser.EnableEvaluator) || !EnableEvaluator}
                    style={{ width: '100%', marginRight: 10, marginTop: 10 }}
                    onClick={() => {
                      setDownloadType('Evaluation');
                      getUserEvaluations('evaluation');
                    }}
                  >
                    <div style={style.spacedBetweenRow} id="exportCompleted">
                      <div>
                        <FontAwesomeIcon icon={faDownload} />
                      </div>
                      <div style={{ ...style.centeredText, fontSize: 15 }}>Evaluation Data (CSV)</div>
                    </div>
                  </Button>

                  <Button
                    color="primary"
                    disabled={!selectedUser || (selectedUser && !selectedUser.EnableEvaluator) || !EnableEvaluator}
                    style={{ width: '100%', marginRight: 10, marginTop: 10 }}
                    onClick={() => {
                      setDownloadType('Evaluation');
                      getUserEvaluations('evaluation', processExportPDF);
                    }}
                  >
                    <div style={style.spacedBetweenRow} id="exportCompleted">
                      <div>
                        <FontAwesomeIcon icon={faDownload} />
                      </div>
                      <div style={{ ...style.centeredText, fontSize: 15 }}>Evaluation PDF</div>
                    </div>
                  </Button>
                </div>
              </CardBody>
            </Card>

            {/* <Button
              color="success"
              disabled={!selectedUser || (selectedUser && !selectedUser.EnableEvaluator)}
              style={{ width: '100%', marginTop: 10 }}
              onClick={() => {
                if (selectedUser) {
                  history.push(`/administrator/evaluate?pk_User=${selectedUser.pk_User}`);
                }
              }}
            >
              <div style={style.spacedBetweenRow} id="evaluateAs">
                <div style={{ ...style.centeredText, fontSize: 15 }}>{`Evaluate as ${UserFirst || ''} ${UserLast ||
                  ''}`}</div>
              </div>
            </Button> */}
          </div>
          {/* <UncontrolledTooltip  target="evaluateAs">
            Not allowed! {UserFirst || ''} {UserLast || ''} is not an Evaluator.
          </UncontrolledTooltip> */}
          {/* {selectedUser && !selectedUser.EnableEvaluator ? (
            <>
              <UncontrolledTooltip target="evaluateAs">
                Not allowed! {UserFirst || ''} {UserLast || ''} is not an Evaluator.
              </UncontrolledTooltip>
              <UncontrolledTooltip target="exportCompleted">
                Not allowed! {UserFirst || ''} {UserLast || ''} is not an Evaluator.
              </UncontrolledTooltip>
            </>
          ) : null} */}
        </div>
      </CardBody>

      <div style={{ visibility: 'hidden' }}>
        <CSVLink
          data={CSVData}
          target="_blank"
          id={`download_actual_evaluation`}
          filename={`Export_Evaluators_Evaluations_${downloadType}_${moment().format('MMM DD, YYYY hhmm A')}`}
        />
        <RouterPrompt
          hasChanges={hasUserDetailChanges}
          okLabel={'Discard Changes'}
          onOk={() => {
            return true;
          }}
        />
      </div>
    </Card>
  );
};

export default UserDetails;
